(function(window) {
  function MoeMap(params) {
    if (params)
      this.setParams(params);

    this.initialize();
  }

  MoeMap.prototype = {
    position: {
      lat: undefined,
      lng: undefined
    },
    created_map: false,
    markers: {},
    map_id: 'map',
    map: undefined,
    map_options: {},
    breakpoints: {
      mobile: 600,
    },
    latlng: undefined,
    address_type: 'postai_cim',
    breeder_data: undefined,
    markerImages: [],
    clusterMarker: [],
    markerBounds: undefined,
    defaultBounds: undefined,
    markerCluster: undefined,
    mcOptions: [
      [{
        url: 'assets/img/markers/counter-circles-small.png',
        height: 45,
        width: 45,
        anchor: [12, 0],
        textColor: '#ffffff',
        textSize: 20,

      }, {
        url: 'assets/img/markers/counter-circles-medium.png',
        height: 58,
        width: 58,
        anchor: [16, 0],
        textColor: '#ffffff',
        textSize: 24
      }, {
        url: 'assets/img/markers/counter-circles-big.png',
        height: 88,
        width: 88,
        anchor: [32, 0],
        textColor: '#ffffff',
        textSize: 26
      }]
    ],
    selectedMarker: false,
    storageMarkers: {},
    initialize: function() {

      if($(window).width() > this.getBreakpoints('mobile')) {
        this.createMarkerImages();
        this.createMarkerBounds();

        this.resetStorage();

        this.createMap();
      }

    },
    addMapEvents: function() {
      var _self = this;

      google.maps.event.addDomListener(window, 'resize', function() {
        _self.map.panTo(_self.latlng);
      });
    },
    createMap: function() {
      var positions = this.getPosition();
      var _self = this;

      if (typeof positions['lat'] != undefined && typeof positions['lng'] != undefined) {
        _self.latlng = new google.maps.LatLng(positions['lat'], positions['lng']);

        var mapOptions = {
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          mapTypeControl: false,
          scrollwheel: false,
          draggable: $(window).width() < _self.getBreakpoints('mobile') ? false : true,
          disableDoubleClickZoom: (_self.getMapOptions('zoomable') == true ? false : true),
          panControl: false,
          streetViewContent: false,
          zoom: parseInt(_self.getMapOptions('zoom')),
          zoomable: _self.getMapOptions('zoombale'),
          zoomControlOptions: {
            small: google.maps.ZoomControlStyle.SMALL
          },
          fullscreenControl: false,
          center: _self.latlng,
          styles: [
    {
        "featureType": "administrative",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "saturation": -100
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "saturation": -100
            },
            {
                "lightness": 40
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "saturation": -10
            },
            {
                "lightness": 30
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "saturation": -60
            },
            {
                "lightness": 10
            }
        ]
    },
    {
        "featureType": "landscape.natural",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "saturation": -60
            },
            {
                "lightness": 60
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            },
            {
                "saturation": -100
            },
            {
                "lightness": 60
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            },
            {
                "saturation": -100
            },
            {
                "lightness": 60
            }
        ]
    }
]
        };

        this.map = new google.maps.Map(document.getElementById(_self.getMapId()), mapOptions);

        _self.createMarkers();
        _self.addMapEvents();
      }
    },
    createMarkers: function() {
      var _self = this;
      var breeder_data = _self.getBreederData();
      
      if (breeder_data.length > 0) {

        for (var i = 0; i < breeder_data.length; i++) {
          if (breeder_data[i][_self.getAddressType()].lat != undefined && breeder_data[i][_self.getAddressType()].lng != undefined) {
            if (!this.markers[breeder_data[i].id]) {
              var latLng = new google.maps.LatLng(breeder_data[i][_self.getAddressType()].lat, breeder_data[i][_self.getAddressType()].lng);

              var marker = new google.maps.Marker({
                'position': latLng,
                'icon': this.markerImages.normal,
                'map': this.map,
                'draggable': false,
                'breeder_id': breeder_data[i].id,
                'my_pos': {
                  'lat': breeder_data[i][_self.getAddressType()].lat,
                  'lng': breeder_data[i][_self.getAddressType()].lng
                }
              });

              _self.markerBounds.extend(latLng);

              _self.addToStorage(breeder_data[i]);

              _self.markers[breeder_data[i].id] = marker;

              _self.clusterMarker.push(marker);

              _self.markers[breeder_data[i].id].array_id = i;

              /*google.maps.event.addListener(_self.markers[breeder_data[i].id], 'click', function() {
                _self.openInfoBox(this, this.position, breeder_data[this.array_id].kapcsolattarto_neve, _self.getBreederAddress(breeder_data, this.array_id, _self.getAddressType()), breeder_data[this.array_id].email);
              });*/
            } else {
              var icon = _self.markerImages.normal;
              this.markers[breeder_data[i].id].marker_type = 0;
              _self.setMarkerIcon(breeder_data[i].id, icon);
            }
          }
        }

        _self.setMarkerCluster();

        _self.setStorage();
      }
    },
    createMarkerBounds: function() {
      this.markerBounds = new google.maps.LatLngBounds();
    },
    createMarkerImages: function() {
      this.markerImages = {
        'normal': new google.maps.MarkerImage('assets/img/markers/marker-2.png', new google.maps.Size(24, 24)),
        'secondary': new google.maps.MarkerImage('assets/img/markers/white-marker.png', new google.maps.Size(20, 27)),
        'hover': new google.maps.MarkerImage('assets/img/markers/red-marker.png', new google.maps.Size(23, 33))
      };
    },
    openInfoBox: function(marker, center, name, address, email) {

      var boxText = document.createElement("div");
      boxText.innerHTML = '<div class="name">' + name + '</div><div class="address">' + address + '</div><a href="mailto:' + email + '" class="email">' + email + '</a>';

      var box_options = {
        content: boxText,
        position: marker.position,
        boxClass: 'breeder-infobox',
        disableAutoPan: false,
        maxWidth: "230px",
        pixelOffset: new google.maps.Size(-145, -133),
        zIndex: null,
        closeBoxMargin: "5px 2px 5px 2px",
        closeBoxURL: "/assets/img/infobox_close.png",
        infoBoxClearance: new google.maps.Size(1, 1),
        isHidden: false,
        pane: "floatPane",
        enableEventPropagation: false
      };

      var info_box = new InfoBox(box_options);
      info_box.open(this.map, marker);
    },
    addToStorage: function(breeder) {
      if (typeof(Storage) != undefined) {
        if (typeof this.storageMarkers[breeder.id] != undefined) {
          this.storageMarkers.push({
            'lat': breeder[this.getAddressType()].lat,
            'lng': breeder[this.getAddressType()].lng,
            'breeder_id': breeder.id,
          });
        }
      }
    },
    resetStorage: function() {
      if (typeof(Storage) != undefined) {
        this.storageMarkers = [];
        this.setStorage();
      }
    },
    removeMarkerCluster: function() {
      if (this.markerCluster != undefined && this.markerCluster.length > 0) {
        this.markerCluster.removeMarkers(this.clusterMarker);
      }
    },
    removeMarkers: function() {
      if (this.markerCluster != undefined) {
        this.markerCluster.removeMarkers(this.clusterMarker);
      }
      if (this.storageMarkers != undefined && this.storageMarkers.length > 0) {
        this.resetStorage();
      }
      for (var i = 0; i < this.clusterMarker.length; i++) {
        if (this.clusterMarker[i] != null && this.markers[this.clusterMarker[i].prop_id]) {
          this.markers[this.clusterMarker[i].prop_id].setMap(null);
          google.maps.event.clearInstanceListeners(this.markers[this.clusterMarker[i].prop_id]);
          google.maps.event.clearInstanceListeners(this.clusterMarker[i]);
          this.markers[this.clusterMarker[i].prop_id] = null;
          this.clusterMarker[i] = null;
        }
      }
      this.clusterMarker = [];
      this.createMarkerBounds();

    },
    setStorage: function() {
      if (typeof(Storage) != undefined) {
        localStorage.setItem('moeMarkers', JSON.stringify(this.getStorage()));
      }
    },
    setParams: function(params) {
      if (params.position) this.setPosition(params.position);
      if (params.map_id) this.setMapId(params.map_id);
      if (params.map_options) this.setMapOptions(params.map_options);
      if (params.breeder_data) this.setBreederData(params.breeder_data);
      if (params.address_type) this.setAddressType(params.address_type);
    },
    setMarkerIcon: function(breeder_id, icon) {
      if (this.markers[breeder_id]) {
        this.markers[breeder_id].setIcon(icon);
      }
    },
    setMarkerCluster: function() {
      var options = {
        'styles': this.mcOptions[0],
      };

      this.markerCluster = new MarkerClusterer(this.map, this.clusterMarker, options);
    },
    setPosition: function(position) {
      this.position = position;
    },
    setCreatedMap: function(value) {
      this.created_map = value;
    },
    setMapId: function(map_id) {
      this.map_id = map_id;
    },
    setMapOptions: function(options) {
      this.map_options = options;
    },
    setBreederData: function(data) {
      this.breeder_data = data;
    },
    setAddressType: function(type) {
      this.address_type = type;
    },
    getStorage: function() {
      return this.storageMarkers;
    },
    getPosition: function(key) {
      if (key) {
        return typeof this.position[key] != undefined ? this.position[key] : '';
      }

      return this.position;
    },
    getMapId: function() {
      return this.map_id;
    },
    getMapOptions: function(key) {
      if (key) {
        return typeof this.map_options[key] != undefined ? this.map_options[key] : '';
      }

      return this.map_options;
    },
    getBreakpoints: function(key) {
      if (key) {
        return typeof this.breakpoints[key] != undefined ? parseInt(this.breakpoints[key]) : 0;
      }

      return this.breakpoints;
    },
    isMapCreated: function() {
      return this.created_map;
    },
    getBreederData: function() {
      return this.breeder_data;
    },
    getAddressType: function() {
      return this.address_type;
    },
    getBreederAddress: function(breeder_data, array_id, address_type) {
      var obj = breeder_data[array_id][address_type];

      var address = obj.ir_szam + ' ' + obj.varos + ' ' + obj.utca + ' ' + obj.hazszam;

      return address;
    },
  }

  window.MoeMap = MoeMap;
})(window);
