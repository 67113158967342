(function(window){
  function EntryForm(form)
  {
    try {
      if (!form)
        throw 'I need a form';

      this.form = form;

      this.init();
    } catch (ex) {
      console.log(ex);
    }
  }

  EntryForm.prototype = {
    sendedForm: false,
    form: undefined,
    error_content: undefined,
    geocoder: undefined,
    init: function() {
      this.error_content = this.form.find('.error-messages');

      if(google.maps !== undefined)
        this.geocoder = new google.maps.Geocoder();

      this.addDOMEvents();
    },
    addDOMEvents: function(){
      var _self = this;

      //$('html, body').scrollTop($('main.main-content').offset().top);

      $('[data-purpose="send-button"]').click(function(){
        _self.sendForm();
      });

  		$("#postai_cim").change(function() {

          if(_self.geocoder !== undefined) {
            _self.geocoder.geocode({address: $(this).val()}, function(results, status) {

    					if (status == 'OK' && results[0]) {

    						var addressComponents = results[0].address_components;

    						for(var address_comp in addressComponents) {

    							switch(addressComponents[address_comp].types[0]) {
    								case "postal_code":
    									document.getElementById('p_ir_szam').value = addressComponents[address_comp].long_name;
    								break;
    								case "locality":
    									document.getElementById('p_varos').value = addressComponents[address_comp].long_name;
    								break;
    								case "route":
    									document.getElementById('p_utca').value = addressComponents[address_comp].long_name;
    								break;
    								case "street_number":
    									document.getElementById('p_hazszam').value = addressComponents[address_comp].long_name;
    								break;
    							}
    						}

    						document.getElementById('p_lat').value = results[0].geometry.location.lat();
    						document.getElementById('p_lng').value = results[0].geometry.location.lng();
    					}
    				});
          }

  		});

      $("#telephely_cime").change(function() {

          if(_self.geocoder !== undefined) {
            _self.geocoder.geocode({address: $(this).val()}, function(results, status) {

    					if (status == 'OK' && results[0]) {

    						var addressComponents = results[0].address_components;

    						for(var address_comp in addressComponents) {

    							switch(addressComponents[address_comp].types[0]) {
    								case "postal_code":
    									document.getElementById('t_ir_szam').value = addressComponents[address_comp].long_name;
    								break;
    								case "locality":
    									document.getElementById('t_varos').value = addressComponents[address_comp].long_name;
    								break;
    								case "route":
    									document.getElementById('t_utca').value = addressComponents[address_comp].long_name;
    								break;
    								case "street_number":
    									document.getElementById('t_hazszam').value = addressComponents[address_comp].long_name;
    								break;
    							}
    						}

    						document.getElementById('t_lat').value = results[0].geometry.location.lat();
    						document.getElementById('t_lng').value = results[0].geometry.location.lng();
    					}
    				});
          }

  		});

      $("#copy_address").change(function() {
          if(this.checked) {
            $("#telephely_cime").prop('disabled', true).addClass('disabled');
            $("[for='telephely_cime']").addClass('disabled');
          } else {
            $("#telephely_cime").prop('disabled', false).removeClass('disabled');
            $("[for='telephely_cime']").removeClass('disabled');
          }
  		});

      $(document).on('confirmation', '.remodal', function () {
        $("#accept_terms").prop('checked', true);
      });

      $(document).on('cancellation', '.remodal', function () {
        $("#accept_terms").prop('checked', false);
      });
    },
    sendForm: function() {
      if (!this.sendedForm) {
        var _self = this;
        this.sendedForm = true;

        $.ajax({
          url:ajax_controller+"handleEntryForm",
          dataType:'json',
          type:'post',
          data:_self.form.serialize(),
          beforeSend: function(){
            // _self.error_content.html('');
            _self.error_content.hide();
            _self.form.find('.has-error').removeClass('has-error');
          },
          success: function(response){
            if (response.success > 0) {
              $('.success-messages').html(response.msg);
              $('.success-messages').show();
              // sendAnalyticsEvent('Success', _self.getModelCode(), _self.getPartnerReference());
            } else{
              // sendAnalyticsEvent('Failed', _self.getModelCode(), _self.getPartnerReference());
              // _self.error_content.html(response.msg);

              _self.error_content.show();
              if(typeof response.keys != 'undefined'){
                var scrollTo;

                for(var i = 0; i < response.keys.length; i++) {

                  if(i == 0) {
                    if($('[for="'+response.keys[i]+'"]').length > 0)
                      scrollTo = '[for="'+response.keys[i]+'"]';
                    else
                      scrollTo = '[name="'+response.keys[i]+'"]';
                  }

                  if(response.keys[i] == "ir_szam" || response.keys[i] == "varos" || response.keys[i] == "utca" || response.keys[i] == "hazszam") {
                    $('[name="postai_cim"]').addClass('has-error');
                    $('[for="postai_cim"]').addClass('has-error');
                  } else if (response.keys[i] == "koca_szarmazasi_hely" || response.keys[i] == "kan_szarmazasi_hely") {
                    $('[name="' + response.keys[i] + '"]').parent('.custom-select').addClass('has-error');
                    $('[for="' + response.keys[i] + '"]').addClass('has-error');
                  } else {
                    $('[name="'+response.keys[i]+'"]').addClass('has-error');
                    $('[for="'+response.keys[i]+'"]').addClass('has-error');
                  }

                }


                  $('html, body').stop().delay(1200).animate({
                    scrollTop: $(scrollTo).offset().top
                  }, 1000);

              }

              setTimeout(function(){
                _self.sendedForm = false;
              }, 1000);
            }
          }
        });
      }
    }
  };

  window.entryForm = EntryForm;
})(window);
