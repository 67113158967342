$(document).ready(function() {
  /* MOBILE */
  $('.venobox').venobox({
    titleattr: 'data-title',
  });
  //create js hook links

  $('.mailtolink').click(function(e) {
    var th = $(this);
    if (th.attr('href') == 'javascript:void(0)') {
      // e.preventDefault();
      var hidden = th.children('.emailhidden');
      th.attr('href', 'mailto:' + hidden.data('user') + '@' + hidden.data('domain'));
    }
  });

  if ($('.cookie-alert-outer').length > 0) {
    setTimeout(function() {
      var height = $('.cookie-alert-outer .content-width').outerHeight();
      $('.cookie-alert-outer').animate({
        'height': height + 'px'
      }, 600);
    }, 550);

    var alertResize = debounce(function() {
      if (!$('.cookie-alert-outer').hasClass('closed')) {
        var height = $('.cookie-alert-outer .content-width').outerHeight();
        $('.cookie-alert-outer').height(height);
      }
    }, 50);

    window.addEventListener('resize', alertResize);

    $('.close-cookie-alert').click(function() {
      $('.cookie-alert-outer').animate({
        'height': '0px'
      }, 600);
      $('.cookie-alert-outer').addClass('closed');

      $.ajax({
        url: ajax_controller + 'setCookieConfirm',
        dataType: 'json',
        type: 'post',
        data: {
          cookie: 1
        },
        success: function() {}
      });
    });
  }

  $("#lightSlider").lightSlider({
    item: 1,
    controls: false,
    pager: false,
    gallery: false
  });

  $("#handleContactForm").click(function(e){

    var formElement = $(".contact-form");
    var error_messages = $('.error-messages');
    var success_messages = $('.success-messages');


    $.ajax({
        type: "POST",
        url: ajax_controller + 'handleContactForm/',
        dataType: "json",
        data: formElement.serialize(),
        beforeSend: function() {
          error_messages.hide();
          formElement.find('.has-error').removeClass('has-error');
        },
        success: function(response) {

          if(response.success) {
            success_messages.html(response.msg);
            success_messages.show();

            formElement[0].reset();

          } else {
            error_messages.show();

            if(typeof response.keys != 'undefined'){
              for(var i = 0; i < response.keys.length; i++) {
                $('[name="'+response.keys[i]+'"]').addClass('has-error');
                $('[for="'+response.keys[i]+'"]').addClass('has-error');
              }
            }
          }
        }
    });
  });

});

function svg(id) {
  return '<span class="svg"><svg class="icon ' + id + '"><use xlink:href="#' + id + '" /></svg></span>';
}

function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function sendAnalyticsEvent(action, category, tag) {
  if (typeof ga != 'undefined' && action && category) {
    if (tag && tag.trim() != '') {
      ga('send', 'event', action, category, tag);
    } else {
      ga('send', 'event', action, category);
    }
  }
}
