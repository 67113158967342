(function(window) {
  function ContactMap(params) {
    if(params) {
      this.setParams(params);
    }

    this.init();
  }

  ContactMap.prototype = {
    position: {
      lat: undefined,
      lng: undefined
    },
    center: {
      lat: undefined,
      lng: undefined
    },
    latlng: undefined,
    map: undefined,
    map_id: undefined,
    map_style: [{
      "featureType": "administrative",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "saturation": -100
          },
          {
              "lightness": 20
          }
      ]
    },
    {
      "featureType": "road",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "saturation": -100
          },
          {
              "lightness": 40
          }
      ]
    },
    {
      "featureType": "water",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "saturation": -10
          },
          {
              "lightness": 30
          }
      ]
    },
    {
      "featureType": "landscape.man_made",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "simplified"
          },
          {
              "saturation": -60
          },
          {
              "lightness": 10
          }
      ]
    },
    {
      "featureType": "landscape.natural",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "simplified"
          },
          {
              "saturation": -60
          },
          {
              "lightness": 60
          }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "off"
          },
          {
              "saturation": -100
          },
          {
              "lightness": 60
          }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "off"
          },
          {
              "saturation": -100
          },
          {
              "lightness": 60
          }
      ]
    }],
    infowindow: undefined,
    marker: undefined,
    texts: {},

    init: function() {
      this.createMap();
    },
    createMap: function() {
      var _self = this;
      var pos = _self.getCenter();

      if( typeof pos['lat'] != undefined && typeof pos['lng'] != undefined ) {

        _self.latlng = new google.maps.LatLng(pos['lat'], pos['lng']);

        var map_options = {
          zoom: 16,
          center: _self.latlng,
          zoomable: false,
          mapTypeControl: false,
          scrollwheel: false,
          panControl: false,
          streetViewContent: false,
          fullscreenControl: false,
          styles: _self.map_style
        }

        _self.map = new google.maps.Map(document.getElementById(_self.getMapId()), map_options);

      }

      _self.createInfoWindow();
      _self.createMarker();

      _self.infowindow.open(_self.map, _self.marker);
    },
    createInfoWindow: function() {
      var _self = this;

      _self.infowindow = new google.maps.InfoWindow({
        content: "<h5>" + _self.getTexts('org_name') + "</h5><div style='margin-top:10px'>" + _self.getTexts('address') + "</div>"
      });
    },
    createMarker: function() {
      var _self = this;

      _self.marker = new google.maps.Marker({
        position: _self.position,
        map: _self.map
      });
    },
    setParams: function(params) {
      if (params.position) this.setPosition(params.position);
      if (params.center) this.setCenter(params.center);
      if (params.map_id) this.setMapId(params.map_id);
      if (params.texts) this.setTexts(params.texts);
    },
    setPosition: function(position) {
      this.position = position;
    },
    setCenter: function(center) {
      this.center = center;
    },
    setMapId: function(map_id) {
      this.map_id = map_id;
    },
    setTexts: function(texts) {
      this.texts = texts;
    },
    getPosition: function() {
      return this.position;
    },
    getCenter: function() {
      return this.center;
    },
    getMapId: function() {
      return this.map_id;
    },
    getTexts: function(key) {
      if(key) {
        return typeof this.texts[key] != undefined ? this.texts[key] : null;
      }

      return this.texts;
    }
  }

  window.ContactMap = ContactMap;
})(window);
